<template>
  <div id="editPage">
    <button class="my-3 px-5 py-2 text-sm text-white border-0 outline-none ml-2 bg-blue-600 hover:bg-brand-hover font-medium rounded-lg cursor-pointer  drop-shadow-sm" @click=routeBack()>Back</button>
    <div id="dashboard_container"></div>       
  </div>
</template>

<script>
import { BoldBI } from "@boldbi/boldbi-embedded-sdk";
import BoldBiConfig from "@/boldbi/config";
import biHelper from "@/boldbi";
import VueCookies from "vue-cookies";

function embedSample(dashboardId) {
  let token = VueCookies.get("token");
  const boldBiHeader = {
    Authorization: "Bearer " + token,
  };

  var boldbiEmbedInstance = BoldBI.create({
    serverUrl: BoldBiConfig.BASE_URL,
    dashboardId: dashboardId,
    embedContainerId: "dashboard_container", // This should be the container id where you want to embed the dashboard
    embedType: BoldBI.EmbedType.Component,
    environment: BoldBI.Environment.Enterprise,
    mode: BoldBI.Mode.View,
    height: "100%",
    width: "100%",
    authorizationServer: {
      url: biHelper.generateEmbedUrl(),
      headers: boldBiHeader,
    },
    onError: function() {
      document.getElementById("dashboard_container").style.textAlign = "center"
      document.getElementById("dashboard_container").style.marginTop = "20%"
      document.getElementById("dashboard_container").style.color = "red"
      document.getElementById("dashboard_container").innerHTML = "<h3>Error in loading dashboard, Please try again</h3>"
    },
    expirationTime: "100000",
  });
  boldbiEmbedInstance.loadDashboard();
}

export default {
  name: "Bi-Dashboard-View",
  data() {
    return {};
  },

  async mounted() {
    const dashboardId = this.$route.params.id;
    embedSample(dashboardId);
  },
  methods: {
    // Function to adjust the iframe height
    adjustIframeHeight() {
      var iframe = document.getElementById("dashboard-frame");
      if (iframe) {
        iframe.height = Math.max(document.documentElement.scrollHeight) + "px";
      }
    },
    routeBack() {
      this.$router.push({ name: "Dashboard - Page 2" });
    },
    checkdropdownClick(docId) {
      let dropdownClicked = false;
      if (docId?.id == 'dashboard_container_embeddedbi_ConfigMenu' || docId?.parentElement.id == 'export') {
        dropdownClicked = true;
      }
      return dropdownClicked;
    },
  },
  beforeDestroy() {    
    document.removeEventListener('click', this.handleDocClick);
  },
};
</script>
<style scoped lang="scss">
#dashboard_container {
  width: 100%;
  height: 100%;
}

#editPage {
  width: 100%;
  height: 100%;
}

#list {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(218, 218, 218, 0.87);
  background: #fff;
  margin-bottom: 12px;
}
</style>
